.tag
  margin-top: 24px
  padding: 8px 12px
  background-color: black
  border-radius: 50vh
  color: white

.tag.success
  background-color: #27ae60

.tag.failure
  background-color: #e74c3c
